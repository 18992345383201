<template>
  <div class="search">
    <div class="main">
      <div class="tips">
        <img src="../../assets/warn.png" alt="" />
        <span> 请输入身份证、手机号、验证手机后查阅案件信息。</span>
      </div>
      <div class="title">诉前调解查询</div>
    </div>
    <!-- <div class="form">
      <div class="label">身份证号</div>
      <div class="value">
        <input
          type="text"
          placeholder="请输入身份证号"
          v-model="idCard"
          class="width-full"
        />
        <span class="clear" v-if="idCard != ''" @click="idCard = ''"></span>
      </div>
      <div class="label">手机号</div>
      <div class="value">
        <input
          type="number"
          placeholder="请输入手机号"
          v-model="telphone"
          class="width-full"
        />
        <span class="clear" v-if="telphone != ''" @click="telphone = ''"></span>
      </div>
      <div class="label">验证码</div>
      <div class="value flex-between">
        <input
          type="text"
          placeholder="请输入验证码"
          v-model="code"
          class="width-half"
        />
        <button class="button-small" @click="getLQCaptcha()" v-if="!isDisabled">
          发送验证码
        </button>
        <button class="button-small-disabled" v-else>
          重新获取({{ seconds }}秒)
        </button>
      </div>
      <button
        class="button"
        @click="lqMpLogin()"
        v-if="idCard && telphone && code"
      >
        案件查询
      </button>
      <button class="button disabled" v-else>案件查询</button>
    </div> -->
    <v-login
      :getCaptcha="getLQCaptcha"
      :mpLogin="lqMpLogin"
      :detailPath="'/liangqing-detail'"
    />
    <div class="footer">
      <div>良庆区人民法院：0771-3114042</div>
      <div class="mid">备案号:浙ICP备19038363号</div>
      <div>技术支持:杭州天迹信息技术服务有限公司</div>
    </div>
  </div>
</template>

<script>
// import { Toast } from "vant";
import login from "../../components/Login.vue";
import { getLQCaptcha, lqMpLogin, addScanRecord } from "../../services/api";
export default {
  name: "Home",
  components: { "v-login": login },
  data() {
    return {
      getLQCaptcha: getLQCaptcha,
      lqMpLogin: lqMpLogin,
    };
  },

  mounted() {
    if (this.$route.query) {
      addScanRecord({
        clientId: this.$route.query.clientId,
        type: this.$route.query.type,
      }).then((res) => {
        if (res.success) {
          // this.settime();
        }
      });
    }
  },
  methods: {
    // getLQCaptcha() {
    //   if (/^1[0-9]{10}$/.test(this.telphone)) {
    //     getLQCaptcha({ object: this.telphone }).then((res) => {
    //       if (res.success) {
    //         this.settime();
    //       } else {
    //         Toast(res.message);
    //       }
    //     });
    //   } else {
    //     Toast("请输入正确的手机号");
    //   }
    // },
    // settime() {
    //   if (this.seconds == 0) {
    //     this.isDisabled = false;
    //     this.seconds = 60;
    //     return;
    //   } else {
    //     this.isDisabled = true;
    //     this.seconds--;
    //   }
    //   setTimeout(() => {
    //     this.settime();
    //   }, 1000);
    // },
    // lqMpLogin() {
    //   if (
    //     !/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
    //       this.idCard
    //     )
    //   ) {
    //     Toast("请输入正确的身份证号");
    //     return;
    //   } else if (!/^1[0-9]{10}$/.test(this.telphone)) {
    //     Toast("请输入正确的手机号");
    //     return;
    //   } else if (!this.code) {
    //     Toast("请输入验证码");
    //     return;
    //   }
    //   lqMpLogin({
    //     mobile: this.telphone,
    //     idCard: this.idCard,
    //     code: this.code,
    //   }).then((res) => {
    //     if (res.success) {
    //       localStorage.setItem("tsToken", res.result.tsToken);
    //       this.$router.push("/liangqing-detail");
    //     } else {
    //       Toast(res.message);
    //     }
    //   });
    // },
  },
};
</script>
<style scoped lang="less">
@import "./index.less";
</style>
